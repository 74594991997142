// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.break {
  display: flex;
  align-items: center;
  padding: 0 60px;
  gap: 3rem;
  width: 100%;
}
.break .breakname {
  width: -moz-fit-content;
  width: fit-content;
}
.break .breakline {
  width: 100%;
  height: 0.5px;
  background-color: rgba(245, 231, 211, 0.3137254902);
  display: flex;
  flex: 1 1;
}

@media screen and (min-width: 0) and (max-width: 480px) {
  .break {
    padding: 0 20px;
    gap: 1.5rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .break {
    padding: 0 30px;
    gap: 1.5rem;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .break {
    padding: 0 40px;
    gap: 2rem;
  }
}
@media screen and (min-width: 991px) and (max-width: 1200px) {
  .break {
    gap: 2rem;
  }
}
@media screen and (min-width: 1920px) {
  .break {
    padding: 0 60px;
    gap: 3rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Breakpoint/breakpoint.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;AACJ;AACI;EACI,uBAAA;EAAA,kBAAA;AACR;AAEI;EACI,WAAA;EACA,aAAA;EACA,mDAAA;EACA,aAAA;EACA,SAAA;AAAR;;AAYA;EACI;IACI,eAAA;IACA,WAAA;EATN;AACF;AAWA;EACI;IACI,eAAA;IACA,WAAA;EATN;AACF;AAYA;EACI;IACI,eAAA;IACA,SAAA;EAVN;AACF;AAaA;EACI;IACI,SAAA;EAXN;AACF;AAkBA;EACI;IACI,eAAA;IACA,SAAA;EAhBN;AACF","sourcesContent":[".break {\n    display: flex;\n    align-items: center;\n    padding: 0 60px;\n    gap: 3rem;\n    width: 100%;\n\n    .breakname {\n        width: fit-content;\n    }\n\n    .breakline {\n        width: 100%;\n        height: .5px;\n        background-color: #f5e7d350;\n        display: flex;\n        flex: 1;\n    }\n}\n\n$xs: 0;\n$sm: 480px;\n$md: 767px;\n$lg: 991px;\n$xl-small: 1200px;\n$xl-large: 1320px;\n$giant: 1920px;\n\n@media screen and (min-width: $xs) and (max-width: $sm) {\n    .break {\n        padding: 0 20px;\n        gap: 1.5rem;\n    }\n}\n@media screen and (min-width: $sm) and (max-width: $md) {\n    .break {\n        padding: 0 30px;\n        gap: 1.5rem;\n    }\n  \n}\n@media screen and (min-width: $md) and (max-width: $lg) {\n    .break {\n        padding: 0 40px;\n        gap: 2rem;\n    }\n\n}\n@media screen and (min-width: $lg) and (max-width: $xl-small) {\n    .break {\n        gap: 2rem;\n    }\n}\n@media screen and (min-width: $xl-small) and (max-width: $xl-large) {\n}\n\n@media screen and (min-width: $xl-large) and (max-width: $giant) {\n}\n@media screen and (min-width: $giant) {    \n    .break {\n        padding: 0 60px;\n        gap: 3rem;\n    }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
