// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.work-preloader {
  width: 100%;
  height: 100%;
  background-color: var(--text);
  color: var(--background);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.work-preloader .loading {
  display: flex;
  gap: 1.5rem;
}
.work-preloader .loading div {
  width: 10px;
  height: 10px;
  background-color: var(--background);
  border-radius: 50%;
}
.work-preloader .work-preload-cover {
  position: absolute;
  width: 100%;
  height: 0%;
  top: 0;
  left: 0;
  background-color: var(--background);
}`, "",{"version":3,"sources":["webpack://./src/components/WorkPreloader/work-preloader.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,6BAAA;EACA,wBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,WAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;EACA,mCAAA;EACA,kBAAA;AACZ;AAGI;EACI,kBAAA;EACA,WAAA;EACA,UAAA;EACA,MAAA;EACA,OAAA;EACA,mCAAA;AADR","sourcesContent":[".work-preloader {\n    width: 100%;\n    height: 100%;\n    background-color: var(--text);\n    color: var(--background);\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 1600;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    .loading {\n        display: flex;\n        gap: 1.5rem;\n\n        div {\n            width: 10px;\n            height: 10px;\n            background-color: var(--background);\n            border-radius: 50%;\n        }\n    }\n\n    .work-preload-cover {\n        position: absolute;\n        width: 100%;\n        height: 0%;\n        top: 0;\n        left: 0;\n        background-color: var(--background);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
