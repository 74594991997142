import React from 'react'
import './footer.scss'

function Footer() {
  return (
    <footer>
        <div className='footer'>
            <p>Designed and Coded by Jasmine Maduafokwa &copy; Portfolio 2023</p>
        </div>
    </footer>
  )
}

export default Footer