// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  position: fixed;
  z-index: 1200;
  background-color: var(--background);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu .menu-cancel {
  position: absolute;
  top: 5%;
  left: 90%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu .menu-cancel i {
  font-size: 22px;
  cursor: pointer;
}
.menu ul {
  text-align: center;
}
.menu ul div {
  overflow: hidden;
}
.menu ul li {
  font-size: 3rem;
  font-family: "Blanvad", serif;
  font-weight: lighter;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/menu.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,aAAA;EACA,mCAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AAEI;EACG,kBAAA;EACA,OAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAP;AAEO;EACC,eAAA;EACA,eAAA;AAAR;AAII;EACI,kBAAA;AAFR;AAIQ;EACI,gBAAA;AAFZ;AAKQ;EACI,eAAA;EACA,6BAAA;EACA,oBAAA;EACA,yBAAA;AAHZ","sourcesContent":[".menu {\n    position: fixed;\n    z-index: 1200;\n    background-color: var(--background);\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    // visibility: hidden;\n\n    .menu-cancel {\n       position: absolute;\n       top: 5%;\n       left: 90%;\n       transform: translate(-50%, -50%);\n       display: flex;\n       justify-content: center;\n       align-items: center;\n\n       i {\n        font-size: 22px;\n        cursor: pointer;\n       }\n    }\n\n    ul {\n        text-align: center;\n\n        div {\n            overflow: hidden;\n        }\n\n        li {\n            font-size: 3rem;\n            font-family: 'Blanvad', serif;\n            font-weight: lighter;\n            text-transform: uppercase;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
