// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader {
  z-index: 2000;
  position: fixed;
  background-color: var(--text);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preloader .jm-logo {
  animation: blink 0.7s ease infinite alternate;
}
.preloader .jm-logo h2 {
  font-size: 9.5rem;
  font-family: "Garelina", serif;
  color: var(--background);
  letter-spacing: -3.9rem;
  transition: all 0.5s ease-in-out;
  letter-spacing: normal;
}
.preloader .preloader-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: var(--background);
  transition-property: all 0.8s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/components/MainPreloader/main-preloader.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,6BAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACI,6CAAA;AACR;AASQ;EACI,iBAAA;EACA,8BAAA;EACA,wBAAA;EACA,uBAAA;EACA,gCAAA;EACA,sBAAA;AAPZ;AAYI;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,UAAA;EACA,mCAAA;EACA,yCAAA;AAVR","sourcesContent":[".preloader {\n    z-index: 2000;\n    position: fixed;\n    background-color: var(--text);\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    \n    .jm-logo {\n        animation: blink .7s ease infinite alternate;\n\n        // @keyframes blink {\n        //     from {\n        //         opacity: 0;\n        //     }\n        //     to {\n        //         opacity: 1;\n        //     }\n        // }\n        h2 {\n            font-size: 9.5rem;\n            font-family: 'Garelina', serif;\n            color: var(--background);\n            letter-spacing: -3.9rem;\n            transition: all .5s ease-in-out;\n            letter-spacing: normal;\n        }\n    }\n    \n\n    .preloader-cover {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 0%;\n        background-color: var(--background);\n        transition-property: all .8s ease-in-out;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
